import {
  ComponentDefinition,
  PageData,
  PageRef,
  PageRouter,
  RouterDescr,
  TPAAppRef,
  TPAComp,
  TPAComponentType,
  TPACompRef,
  TPARef,
} from './types/common';
import { PAGE_TITLES } from '../config/constants';
import { GroupsAppSpec } from './types/AppSpec';
import { FlowEditorSDK, ComponentRef } from '@wix/yoshi-flow-editor';

interface PageLink {
  type: string;
  pageId: any;
}

class EditorWrapper {
  private appData: any;

  constructor(
    private readonly editorSDK: FlowEditorSDK,
    private readonly appDefId: string,
  ) {}

  async installApplication(appDefinitionId: string) {
    console.log(`[GROUPS] installing ${appDefinitionId}`);
    if (!(await this.isAppInstalled(appDefinitionId))) {
      return this.editorSDK.application.install(this.appDefId, {
        appDefinitionId,
        originInfo: {},
      });
    }
    console.log(`[GROUPS] app ${appDefinitionId} is already installed`);
  }

  // Only wix apps are allowed installed in this way
  async installTPA(appDefinitionId: string): Promise<TPAAppRef> {
    if (!(await this.isAppInstalled(appDefinitionId))) {
      return this.editorSDK.document.tpa.add.application(this.appDefId, {
        appDefinitionId,
      });
    }
    // TODO: get pageRef by appId
    return Promise.resolve({ instanceId: appDefinitionId });
  }

  private installTPAComponent(compDescr: TPAComp): Promise<TPACompRef> {
    return this.editorSDK.document.tpa.add.component(this.appDefId, compDescr);
  }

  installTPAPage(appDefinitionId: string, pageId: string): Promise<TPACompRef> {
    const compDescr = {
      componentType: TPAComponentType.Page,
      appDefinitionId,
      page: {
        pageId,
      },
    };
    return this.installTPAComponent(compDescr);
  }

  async isAppInstalled(appDefinitionId: string) {
    return this.editorSDK.document.tpa.isApplicationInstalled(this.appDefId, {
      appDefinitionId,
    });
  }

  async getAPI(appDefinitionId: string) {
    return this.editorSDK.application.getPublicAPI(this.appDefId, {
      appDefinitionId,
    });
  }

  async isControllerAlreadyExist(controllerType: string) {
    const controllers = await this.editorSDK.controllers.listAllControllers(
      this.appDefId,
    );
    for (const controller of controllers) {
      const data = await this.editorSDK.controllers.getData(
        this.appDefId,
        controller,
      );
      if (data.type === controllerType) {
        return true;
      }
    }
    return false;
  }

  getMasterRef(): Promise<PageRef> {
    return this.editorSDK.siteSegments.getSiteStructure(this.appDefId);
  }

  addRouter(descr: RouterDescr) {
    return this.editorSDK.document.routers.add(this.appDefId, descr);
  }

  connectPage(pageRouter: PageRouter): Promise<void> {
    return this.editorSDK.document.routers.pages.connect(
      this.appDefId,
      pageRouter,
    );
  }

  private addComponent(descr: {
    componentDefinition: ComponentDefinition;
    pageRef: PageRef;
  }) {
    return this.editorSDK.components.add(this.appDefId, descr);
  }

  installController(pageRef: PageRef, controllerType: string) {
    const descr = {
      componentDefinition: {
        componentType: 'platform.components.AppController',
        data: {
          type: 'AppController',
          controllerType,
          applicationId: this.appDefId,
          settings: JSON.stringify({}),
        },
      },
      pageRef,
    };
    return this.addComponent(descr);
  }

  async addToManagedPages(pages: TPARef[]) {
    return pages.forEach(async (page) => {
      const { title, pageRef } = page;
      // @ts-expect-error
      const pagetitle = PAGE_TITLES[title];
      return this.updatePage(pageRef, {
        managingAppDefId: this.appDefId,
        title: pagetitle,
      });
    });
  }

  private updatePage(pageRef: any, data: Partial<PageData>) {
    return this.editorSDK.document.pages.data.update(this.appDefId, {
      pageRef,
      data,
    } as any);
  }

  async openAppPage(
    pageId: string,
    appDefId?: string,
  ): Promise<PageLink | null> {
    // code is borrowed from Wix Forums, see
    // https://github.com/wix-private/app-market/blob/80fa930daf5076db9e41b6a49b4346da39a4652f/communities/communities-forum-app/src/assets/editor-script.js#L311
    const [{ applicationId }, allPages] = await Promise.all([
      this.getDataByAppDefId(appDefId || this.appDefId),
      this.getAllPages(),
    ]);

    const page = allPages.find(
      ({ tpaPageId, tpaApplicationId }) =>
        tpaPageId === pageId && tpaApplicationId === applicationId,
    );

    if (page) {
      const pageLink: PageLink = { type: 'PageLink', pageId: page.id };
      return this.editorSDK.document.pages
        .navigateTo(this.appDefId, {
          pageLink,
        } as any)
        .then(() => pageLink);
    }
    return null;
  }

  getDataByAppDefId(appDefId: string) {
    return this.editorSDK.tpa.app.getDataByAppDefId(this.appDefId, appDefId);
  }

  getAllPages(): Promise<PageData[]> {
    return this.editorSDK.pages.data.getAll(this.appDefId) as any;
  }

  async getAppData<T>(appDefinitionId?: string): Promise<T> {
    if (!this.appData) {
      this.appData = await this.editorSDK.document.tpa.app.getDataByAppDefId(
        this.appDefId,
        appDefinitionId || this.appDefId,
      );
    }
    return this.appData;
  }

  getLocale() {
    return this.editorSDK.environment.getLocale();
  }

  /**
   * https://github.com/wix-private/js-platform-editor-sdk/blob/ce26b1d2f6920f77caa7c293e05a6bc01147e38b/js/modules/editor.ts#L901
   * @param  url - The relative URL in the dashboard
   */
  openDashboard(url: string) {
    return this.editorSDK.editor.openDashboardPanel(this.appDefId, {
      url,
      closeOtherPanels: false,
    });
  }

  /**
   * Updates the state for one or more pages.
   * Use this functionality to give different edit experience to different pages by defining different
   * pages actions and settings in the appManifest.
   * https://github.com/wix-private/js-platform-editor-sdk/blob/0eac76ff130a1e884c45ef023cb7e5eac865de18/js/modules/document/pages/pages.ts#L150
   */
  setPageState(state: { [index: string]: PageRef[] }): Promise<void> {
    return this.editorSDK.document.pages.setState(this.appDefId, {
      state,
    });
  }

  async addPage() {
    await this.editorSDK.history.add(this.appDefId, { label: 'History' });
    const pageDef = {
      title: 'New Page',
      definition: {
        data: {
          hideTitle: false,
          isLandingPage: true,
          indexable: false,
          hidePage: true,
          managingAppDefId: this.appDefId,
          pageSecurity: {
            requireLogin: false,
          },
        },
        components: [],
      },
      shouldAddMenuItem: false,
    };
    const pageRef = await this.editorSDK.pages.add(
      this.appDefId,
      pageDef as any,
    );

    return this.editorSDK.editor.openPagesPanel(this.appDefId, {
      renameEnabled: false,
      pageRef,
    } as any);
  }

  deletePages(groupPages: TPARef[]) {
    return Promise.all(
      groupPages.map((ref) => {
        return this.editorSDK.document.pages.remove(this.appDefId, ref as any);
      }),
    );
  }

  deletePage(ref: any) {
    this.editorSDK.document.pages.remove(this.appDefId, ref);
  }

  deleteApp() {
    return this.editorSDK.document.tpa.app.delete(this.appDefId);
  }

  async save() {
    return this.editorSDK.document.save();
  }

  async getComponentRef(widgetId: string) {
    const { applicationId } = await this.getAppData<GroupsAppSpec>();
    const comps =
      (await this.editorSDK.tpa.app.getAllCompsByApplicationId(
        this.appDefId,
        applicationId,
      )) || [];
    const groupsComponent = comps.find((comp) => comp.widgetId === widgetId);
    if (groupsComponent) {
      return this.editorSDK.components.getById(this.appDefId, {
        id: groupsComponent.id,
      });
    }
  }

  async openModal(
    url: string,
    title: any,
    componentRef: ComponentRef,
  ): Promise<any> {
    return this.editorSDK.editor.openComponentPanel(this.appDefId, {
      url,
      type: this.editorSDK.editor.PanelType.Settings,
      componentRef,
      width: 744,
      height: 363,
      title,
    });
  }

  getAppInstance(): Promise<string> {
    return this.editorSDK.document.info.getAppInstance(this.appDefId);
  }

  async navigate(id: string) {
    await this.editorSDK.document.pages.navigateTo(this.appDefId, {
      pageRef: { id },
    });
  }

  async addEventListener(eventType: any, handler: any) {
    await this.editorSDK.addEventListener(eventType, handler);
  }
  async removeEventListener(eventType: any, handler: any) {
    await this.editorSDK.removeEventListener(eventType, handler);
  }
}

export default EditorWrapper;
