
  import * as userEditorScriptEntry from '/home/builduser/work/66392d99e07b9755/packages/social-groups-ooi/src/editor.app.ts';
  const editorScriptEntry = userEditorScriptEntry;

  

  import { initI18nWithoutICU as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  

    var createExperiments = null;
    var createWidgetExperiments = null;
    

  
import { createHttpClient } from '@wix/yoshi-flow-editor/runtime/esm/controller/httpClientProp';
    


  import { createOwnerBILogger as biLogger } from '/home/builduser/work/66392d99e07b9755/packages/social-groups-ooi/target/generated/bi/createBILogger.ts';

  
  import { editorReadyWrapper, onEventWrapper, createFlowAPIFabric, exportsWrapper, getAppManifestWrapper } from '@wix/yoshi-flow-editor/runtime/esm/editorScript.js';

  let editorReady = editorScriptEntry.editorReady;
  const onEvent = onEventWrapper({ onEvent: editorScriptEntry.onEvent });
  const sentryConfig = {
    DSN: 'https://ea002ae7678c4b8db9a0bb5b9175faaf@sentry.wixpress.com/1166',
    id: 'ea002ae7678c4b8db9a0bb5b9175faaf',
    projectName: 'social-groups-ooi',
    teamName: 'wix-groups',
    errorMonitor: true,
  };
  const experimentsConfig = {"centralized":true,"scopes":["specs.groups","ricos"]};
  const translationsConfig = {"icuEnabled":false,"defaultTranslationsPath":"/home/builduser/work/66392d99e07b9755/packages/social-groups-ooi/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hu","id","it","ja","ko","lt","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};
  const defaultTranslations = {"groups-web.app.delete":"Delete Groups","groups-web.page.delete":"Delete","groups-web.page.info":"Page Info","groups-web.page.seo":"SEO Basics","groups-web.page.layout":"Layouts","groups-web.page.permissions":"Permissions","groups-web.page.info.title":"What’s a group page?","groups-web.page.info.p1":"This page opens when someone clicks on a group from the group list. Depending on the group’s privacy settings, the page displays the group's info, discussions, members and media.","groups-web.page.info.title2":"How does this page behave?","groups-web.page.info.p2":"This is a template page. Any changes you make will affect all group pages. You can manage group specific settings in the","groups-web.page.info.link":"Groups Dashboard","groups-web.manage.actions.main":"Go to Dashboard","groups-web.manage.actions.create.group":"Create New Group","groups-web.manage.actions.setup":"Set up the Group","groups-web.manage.actions.add.widget":"Add Group Widget","groups-web.settings.addons":"Add-Ons"};
  const createFlowAPI = createFlowAPIFabric({
    sentryConfig,
    experimentsConfig,
    translationsConfig,
    defaultTranslations,
    biLogger,
    shouldUseEssentials: true,
    artifactId: 'social-groups-ooi',
    appDefId: '148c2287-c669-d849-d153-463c7486a694',
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: 'assets/locales',
  });

  const useAPIsFromExports = typeof editorScriptEntry.exports === "function";
  if (editorReady) {
    editorReady = editorReadyWrapper({
      editorReady,
      createFlowAPI,
      shouldSkipAPIOverrides: useAPIsFromExports,
    });
    if (false) {
      const hot = require('@wix/yoshi-flow-editor/build/cjs/wrappers/editorScriptHot.js').hot;
      editorReady = hot(module, editorReady)
    }
  }
  export { editorReady };
  export { onEvent };
  export const exports = useAPIsFromExports ? exportsWrapper(editorScriptEntry.exports) : editorScriptEntry.exports;
  export const getAppManifest = editorScriptEntry.getAppManifest ? getAppManifestWrapper(editorScriptEntry.getAppManifest, createFlowAPI) : undefined;
  export const handleAction = editorScriptEntry.handleAction;
  
